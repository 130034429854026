<template>
    <div class="wrap-top2" style="margin: 0px;width:100%;">
        <a href="/h5"><img class="logo" :src="webInfo.logo"/></a>
        <div class="menu-toggle2" @click="openDrawer">
            <el-icon class="el-icon-menu" ></el-icon>

        </div>
        <el-drawer
                :wrapperClosable="true"
                destroy-on-close
                :withHeader="false"
                :show-close="false"
                :visible.sync="drawer"
                direction="rtl"
        >
            <el-menu class="menu" :default-active="activeIndex"
                     mode="horizontal" unique-opened menu-trigger="click"	:collapse-transition="false">


                <template v-for="(item,index) of dataList" >
                    <el-menu-item  :key="item.id" @click="nav($event,item.url,item.id)" v-if="item.itemList.length==0" :index="item.id+''" >
                        <span>{{item.name}}</span>
                    </el-menu-item>
                    <el-submenu  v-else :key="index" :index="item.id+''">
                        <template slot="title"><span>{{item.name}}</span></template>
                        <el-menu-item :key="subItem.id" @click="nav($event,subItem.url,item.id+'-'+subItem.id)" style="font-size:18px;"
                                      v-for="(subItem) of item.itemList"
                                      :index="item.id+'-'+subItem.id">
                            <span>{{subItem.name}}</span>

                        </el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name:"H5Header",
        data(){
            return {
                drawer:false,
                activeIndex: "0",
                dataList:[
                    // {name:'首页',url:'/',itemList:[]},
                    // {name:'学院介绍',url:'/article?id=1',itemList:[
                    //         {name:'学校概况',url:'/article?id=2'},{name:'学院简介',url:'/article?id=3'}
                    //     ]},
                    // {name:'党建工作',url:'/article?id=4',itemList:[]},
                    // {name:'网络函授',url:'/article?id=5',itemList:[]},
                    // {name:'自学考试',url:'/article?id=6',itemList:[]},
                    // {name:'教育培训',url:'/article?id=7',itemList:[]},
                    // {name:'学习支持',url:'/article?id=8',itemList:[]},
                ],
                webInfo:{}
            }
        },
        created(){
            this.getList()
            this.getWebInfo()
        },
        methods:{
            openDrawer(){
                console.log(1)
                this.drawer=true
            },
            getList(){
                let id=this.$route.query.id
                this.$axios.get("/front/company/menuList").then(res=>{
                    let data=res.data
                    if(data.success){
                        this.dataList=data.result
                        this.dataList.unshift( {name:'首页',url:'/',key:'index',itemList:[],id:"aaaa"})
                        if (id) {
                            this.activeIndex=this.dataList.filter(a=>(a.id).indexOf(id)!==-1).map(a=>a.id).join("");
                        }else{
                            this.activeIndex="aaaa"

                        }
                    }
                });
            },
            getWebInfo(){
                this.$axios.get("/front/company/webInfo").then(res=>{
                    if(res.data.success){
                        this.webInfo=res.data.result
                        this.$emit("ok",res.data.result)

                    }
                });
            },
            nav(e,url,id){
                this.activeIndex=id+""
                if(url==='/'){
                    this.$router.push({path:'/h5'})

                }else{
                    url=url.replace("article","h5_article")
                    this.$router.push({path:url+"&c="+Number(new Date())})

                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .wrap-top2{
        .menu-toggle2{
            flex: 1;
            font-size: 24px;
            text-align: right;
            margin-right: 10px;
        }

    }


    .wrap-top2 {
        display: flex;
        /*margin: 0 90px;*/
        align-items: center;

    }

    .wrap-top2 .menu {
        font-family: Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        border-bottom: none !important;
    }

    .wrap-top2 .menu span {
        font-size: 18px !important;
        color: #000000;
    }

    .wrap-top2 .logo {
        width: 300px;
        height: 49px;
        object-fit: contain;
    }
</style>
