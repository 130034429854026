<template>
    <div class="div-footer-wrap">
        <div class="div-footer">
            <div class="div-qrcode">
                <span class="footer-weixin-title">{{webInfo.footerImageText}}</span>
                <img :src="webInfo.footerImage" class="footer-weixin"/>
            </div>
            <div class="div-footer-desc">
                <div>{{webInfo.copyright}}</div>
                <div>{{webInfo.address}}</div>
                <div>
                    <a target="_blank" class="footer-beian" href="http://www.beian.gov.cn/portal/index.do">
                        {{webInfo.otherinfo}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data(){
            return {
                webInfo:{}
            }
        },
        created(){
          this.getWebInfo();
        },
        methods:{
            getWebInfo(){
                this.$axios.get("/front/company/webInfo").then(res=>{
                    if(res.data.success){
                        this.webInfo=res.data.result
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .footer-beian{
        text-decoration: none;
        color:white;
        &:hover{
            text-decoration: underline;
        }
    }
    /* 底部 */
    .div-footer-wrap{
        height: 152px;
        background-color: rgba(0,43,74,0.8);
        margin-top:-5px;
        display: flex;
        align-items: center;
        padding: 0 70px;
    }
    .div-footer{
        display: flex;
        height: 120px;
        align-items: center;
    }
    .div-qrcode{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .div-footer-desc{
        color:#D9EFFF;
        font-size: 12px;
        margin-left: 100px;
        line-height: 26px;
        text-align: left;
    }
    .div-footer .footer-weixin-title{
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }
    .div-footer .footer-weixin{
        height: 80px;
        width: 80px;
        background-color: rgba(211, 227, 238,1);
    }


</style>
