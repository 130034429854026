import { render, staticRenderFns } from "./H5Index.vue?vue&type=template&id=5c41f2dc&scoped=true&"
import script from "./H5Index.vue?vue&type=script&lang=js&"
export * from "./H5Index.vue?vue&type=script&lang=js&"
import style0 from "./H5Index.vue?vue&type=style&index=0&id=5c41f2dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c41f2dc",
  null
  
)

export default component.exports