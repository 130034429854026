import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuObj:null,
    saveWebObj:null,
    domainView:'https://oa.eduhyx.com/jeecg-boot/sys/common/view/'
  },
  mutations: {
    saveMenu(state,menuObj){
      state.menuObj=menuObj
    },
    saveWebObj(state,obj){
      state.saveWebObj=obj
    }
  },
  actions: {
  },
  modules: {
  }
})
