<template>
    <div>
        <Header></Header>
        <div class="article-wrap" id="article-wrap">
            <div class="article-left">
                <div class="article-header" >
                    学院介绍
                </div>
                <div class="article-item" :class="item.selected?'active':''" @click="clickMenu(index)" v-for="(item,index) of menuList" :key="index">
                    {{item.name}}
                </div>

            </div>
            <div class="article-right">
                <div class="right-header">
                    <span class="a" >学院介绍</span>
                </div>
                <div class="right-content" v-for="item of 10" :key="item">
                    <h3>2021年网络教育招生严正声明</h3>
                    <span>2020-10-12</span>
                </div>
                <el-pagination style="margin-top: 30px"
                        layout="prev, pager, next"
                        :total="1000">
                </el-pagination>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
    import Header from "./Header";
    import Footer from "./Footer";
    export default {
        name: "ArticleList",
        components: {Footer, Header},
        data(){
          return {
              menuList:[
                  {name:'学院介绍1',url:'',selected:false},
                  {name:'学院介绍2',url:'',selected:false},
                  {name:'学院介绍3',url:'',selected:false},
                  {name:'学院介绍4',url:'',selected:false},
                  {name:'学院介绍5',url:'',selected:false},
                  {name:'学院介绍6',url:'',selected:false},
                  {name:'学院介绍7',url:'',selected:false},
                  {name:'学院介绍8',url:'',selected:false},
                  {name:'学院介绍9',url:'',selected:false},
                  {name:'学院介绍10',url:'',selected:false},
              ],
              c:'',
              content:' 武汉理工大学是教育部直属全国重点大学，是首批列入国家“211工程”和“双一流”建设高校， 是教育部和交通运输部、国家国防科技工业局共建高校。70年来，学校共培养了近60万名高级专门人才， 是教育部直属高校中为建材建工、交通、汽车三大行业培养人才规模最大的学校，已成为我国“三大行业”高层次人才培养和科技创新的重要基地。 目前学校普通本科生36000余人，博士、硕士生18000余人，留学生1700余人。\n' +
                  '                    学校长期的育人实践，形成了特色鲜明的办学思想体系：确立了“建设让人民满意、让世人仰慕的优秀大学”的大学理想， “厚德博学、追求卓越”的大学精神，“育人为本、学术至上”的办学理念，“实施卓越教育、培养卓越人才、创造卓越人生”的卓越教育理念。 学校致力于为社会培养一代又一代以智慧引领人生、具有卓越追求和卓越能力的卓越人才。\n' +
                  '                    学校现有马房山校区、余家头校区和南湖校区，占地近4000亩，校舍总建筑面积178.28万平方米，4座现代化图书馆藏书310.91万册。 设有25个学院（部），4个国家重点实验室（工程中心）。现有教职工5477人，其中专任教师3243人，中国科学院院士1人，中国工程院院士4人， 国家教学名师奖获得者3人，“百千万人才工程”国家级人选12人。\n' +
                  '                    学校已形成以工学为主，理、工、经、管、艺术、文、法等多学科相互渗透、协调发展的学科专业体系。 现有一级学科博士学位授权点19个，一级学科硕士学位授权点46个，博士后科研流动站17个；有22个硕士专业学位授权类别，39个硕士专业学位授权领域。 材料科学进入ESI全球学科排名前1‰，工程学和化学进入ESI全球学科排名前3‰。现有本科专业91个，其中国家特色专业15个、卓越工程师教育培养计划试点专业28个、 国家综合改革试点专业4个、国家战略性新兴产业专业2个。现有国家级精品在线开放课程15门、国家级精品资源共享课17门、国家级精品视频公开课8门。 拥有国家级教学团队5个、教育部人才培养模式实验区4个、国家级实验教学示范中心5个、国家级虚拟仿真实验教学中心1个、国家工科基础课程教学基地1个、 国家级工程实践教育中心13个、国家国际化示范学院1个、全国创业孵化示范基地1个、全国高校实践育人创新创业基地1个。\n' +
                  '                    近年来，学校培养了一批全国优秀大学生代表，2人获“中国青年五四奖章”，3人获“中国大学生年度人物”称号，4人获“中国大学生自强之星标兵”称号， 12人获“中国大学生自强之星”称号，4人获“中国青年志愿者优秀个人奖”，6人获“中国青少年科技创新奖”。近年来，学校在多项全国高水平大学生科技竞赛中成绩名列前茅。 2018年，大学生参加竞赛获省部级以上奖项740项（其中国家级奖463项）。学校毕业生一次就业率近几年保持在95%以上。\n' +
                  '                    学校建有材料复合新技术国家重点实验室、硅酸盐建筑材料国家重点实验室、光纤传感技术国家工程实验室、 国家水运安全工程技术研究中心等40个国家级和省部级科研基地，建有内河智能航运交通运输部协同创新中心、汽车零部件技术湖北省协同创新中心、 安全预警与应急联动技术湖北省协同创新中心等3个省部级协同创新中心，与地方政府和行业企业共建科技合作与成果转化机构230余个。 2010年以来，学校以第一完成单位获国家科技奖励15项，位居全国高校前列。\n' +
                  '                    以创新求发展、以服务求支持、以贡献促共建,以建成“整体水平国内一流，部分学科水平国际一流”的多科性、开放式、有特色的研究型大学为发展目标,抓住机遇,深化改革,团结一致,开拓创新,为科教兴国做出更大的贡献。\n' +
                  '\n' +
                  '                    学校与美国、英国、日本、法国、澳大利亚、俄罗斯、荷兰等国家的190多所大学和科研机构建立了人才培养和科技合作关系， 聘请了300余名国外知名学者担任学校战略科学家、客座和名誉教授。2007年以来，学校先后获批建立了材料复合新技术与先进功能材料、高性能船舶关键技术、 功能薄膜新材料先进制备技术及工程应用、新能源汽车科学与关键技术、环境友好建筑材料等5个学科创新引智基地和材料复合新技术国际联合实验室、 环境友好建筑材料国际科技合作基地、智能航运与海事安全国际科技合作基地、材料复合新技术与新材料国际联合实验室等4个国际科技合作基地。 2009年以来，学校先后与美国、英国、意大利、荷兰、日本的著名高校建立了16个高水平国际合作研究平台，包括武汉理工大学—密歇根大学新能源材料技术联合实验室、 武汉理工大学—南安普顿大学高性能船舶技术联合中心、武汉理工大学-赛默飞世尔科技联合实验室等。 2016年，学校与英国威尔士三一圣大卫大学合作建立的首个海外校区正式运行。2018年，学校与法国艾克斯-马赛大学合作成立武汉理工大学艾克斯马赛学院。\n' +
                  '\n' +
                  '                    2019年，学校同时进入THE世界大学排行榜、US News世界大学排行榜、ARWU世界大学学术排行榜及QS世界大学排行榜。'
          }
        },
        created(){
           this.getList()
        },
        mounted() {
            this.clearMenu();
            let id=this.$route.query.id



            this.menuList[id].selected=true
            this.genContent()
            document.body.scrollTop=0
        },
        watch:{
          "$route":function(val){
              this.clearMenu();
              let id=val.query.id
              this.menuList[id].selected=true
              this.genContent()
              document.body.scrollTop=0


          }
        },
        methods:{
            getList(){
                this.$axios.get("/front/company/menuList").then(res=>{
                    let data=res.data
                    if(data.success){
                        this.menuList=data.result
                    }
                });
            },
            clearMenu(){
                this.menuList.forEach(a=>{
                    a.selected=false
                })
            },
            genContent(){
                let number = Math.random()*3000;
                let content=this.content
                this.c=content.substr(0,number)
            },
            clickMenu(index){
                this.clearMenu();

                let item=this.menuList[index]
                item.selected=true
                this.menuList.splice(index,1,item)

                this.genContent()
            }
        }
    }
</script>

<style scoped lang="scss">
.article-wrap{
    background-color: #e8edf1;
    padding:70px 90px;
    display: flex;
    height: 100%;

    .article-left{

        background-color: #ededf0;
        flex:1;
        .active{
            background-color: #0071c1 !important;
            color: #FFFFFF !important;

        }
        .article-header{
            font-size:22px;
            font-weight: 600;
            background-color:#dfe1e2;
            color:#333333;
            padding:30px 0;
            border-bottom: 1px solid #d1d1d1;
        }
        .article-item{
            font-size:16px;
            background-color:#dfe1e2;
            color:#000000;
            padding:14px 0;
            border-bottom: 1px solid #d1d1d1;
            cursor: pointer;
        }
    }
    .article-right{
        flex:6;
        background-color: #FFFFFF;
        padding:40px 60px;
        .right-header{
            display: flex;
            border-bottom: 1px solid #f1f1f1;

            .a{
                font-weight: bold;
                color:#000000;
                font-size: 22px;
            }
            .b{
                flex:1;
                border-bottom: 1px solid #f1f1f1;
                text-align: right;
                color: #666666;
                font-size: 14px;
                font-family: Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
        }
        .right-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
        }
    }
}
</style>
