<template>
    <div style="height: 100%">
        <Header></Header>
        <div style="height: 100%;display: flex;flex-direction: column;">
            <div style="flex: 1" class="article-wrap">
                <div class="article-left">
                    <div class="article-header">
                        <router-link  style="text-decoration: none;color: #000000" :to="'/article?id='+navId+'&c='+Number(new Date())" >  {{parentName}}</router-link>
                    </div>
                    <div class="article-item" :class="item.selected?'active':''" @click="clickMenu(index)" v-for="(item,index) of menuList" :key="index">
                        {{item.name}}
                    </div>

                </div>
                <div class="article-right">
                    <div class="right-header">
                        <span class="a">{{menuText}}</span>
                        <span class="b">当前位置:<router-link  style="text-decoration: underline;color: #000000" :to="'/article?id='+navId+'&c='+Number(new Date())" >{{parentName}} </router-link>/ {{menuText}}</span>
                    </div>
                    <div class="right-content">
                        <!--                    如果存一条记录则直接显示-->
                        <div class="content-one" v-if="count===1">
                            <h3 style="text-align: center">{{obj.title}}</h3>
                            <div v-html="obj.content"></div>
                        </div>
                        <!--                    如果存在多条则显示列表-->
                        <div  v-else-if="count>1">
                            <div @click="getArticle(item.id)" class="right-content-list" v-for="(item,index) of obj"  :key="index">
                                <span>{{item.title}}</span>
                                <span>{{item.createTime}}</span>
                            </div>
                            <el-pagination style="margin-top: 30px;text-align: right"
                                           @current-change="pageChange"
                                           @prev-click="pageChange"
                                           @next-click="pageChange"
                                           layout="prev, pager, next"
                                           :total="count" :page-size="pageSize">
                            </el-pagination>
                        </div>
                        <div v-else class="content-zero">
                            暂无内容
                        </div>


                    </div>
                </div>
            </div>
            <Footer></Footer>

        </div>


    </div>
</template>

<script>
    import Header from "./Header";
    import Footer from "./Footer";
    export default {
        name: "Article",
        components: {Footer, Header},
        data(){
          return {
              menuList:[
                  // {name:'学院介绍1',url:'',selected:false},
                  // {name:'学院介绍2',url:'',selected:false},
                  // {name:'学院介绍3',url:'',selected:false},
                  // {name:'学院介绍4',url:'',selected:false},
                  // {name:'学院介绍5',url:'',selected:false},
                  // {name:'学院介绍6',url:'',selected:false},
                  // {name:'学院介绍7',url:'',selected:false},
                  // {name:'学院介绍8',url:'',selected:false},
                  // {name:'学院介绍9',url:'',selected:false},
                  // {name:'学院介绍10',url:'',selected:false},
              ],
              parentName: "",
              navId: '',
              c:'',
              title:'',
              content:'',
              count:0,
              dataList:[],
              obj:null,
              currentPage:1,
              pageSize:10
          }
        },
        async created(){
            let id=this.$route.query.id
            let articleId=this.$route.query.articleId
            // if(this._isMobile){
            //     this.$router.push({path: '/h5_article?id=' + id + "&articleId=" + articleId})
            //
            // }
            this.navId=id
            await this.getData(id);



            if (articleId!==undefined) {
                this.getArticle(articleId)
            }else{
                this.genContent();
            }
        },
        mounted(){
            // let id=this.$route.query.id
            // let articleId=this.$route.query.articleId
            // if(this._isMobile){
            //     this.$router.push({path: '/h5_article??id=' + id + "&articleId=" + articleId})
            //
            // }
        },
        computed:{
          menuText:function(){
              return this.menuList.filter(a=>a.selected).map(a=>a.name).join("")||this.parentName
          }
        },
        watch:{
          "$route":function(val){
              // this.clearMenu();
              let id=val.query.id
              this.navId=id
              // this.menuList[id].selected=true
              this.getData(id)

              this.genContent()

          }
        },
        methods:{
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            pageChange(page){
                this.currentPage=page
                this.genContent()
            },
            getArticle(id){
                // this.$axios.get("/front/company/getArticle?id="+id).then(res=>{
                //     if(res.data.success){
                //         this.obj=res.data.result.obj
                //         this.count=res.data.result.count
                //     }
                // })
                // console.log("/article?id='"+this.navId+"&articleId="+id+"'&c='"+Number(new Date()))
                this.$router.push("/articleDetail?id="+this.navId+"&articleId="+id+"&c="+Number(new Date()))
            },
            getData(id){
                // let id=this.$route.query.id
                return new Promise(resolve => {
                    this.currentPage=1
                    this.$axios.get("/front/company/getMenuListById?menuId="+id).then(res=>{
                        let data=res.data
                        if(data.success){
                            this.menuList=data.result.menuList
                            this.parentName=data.result.parentName

                        }
                        resolve()
                    });
                })
            },
            clearMenu(){
                this.menuList.forEach(a=>{
                    a.selected=false
                })
            },
            genContent(){
                this.$axios.get("/front/company/getArticleByMenuId?menuId="+this.navId+"&currentPage="+this.currentPage+"&pageSize="+this.pageSize).then(res=>{
                    if(res.data.success){
                        this.obj=res.data.result.obj
                        this.count=res.data.result.count
                    }
                })
            },
            clickMenu(index){
                this.clearMenu();
                let item=this.menuList[index]
                item.selected=true
                this.menuList.splice(index,1,item)

                this.navId=item.id
                this.genContent()
            }
        }
    }
</script>

<style scoped lang="scss">
.article-wrap{
    background-color: #e8edf1;
    padding:40px 90px;
    display: flex;
    .article-left{

        background-color: #ededf0;
        flex:1;
        .active{
            background-color: #0071c1 !important;
            color: #FFFFFF !important;

        }
        .article-header{
            font-size:22px;
            font-weight: 600;
            background-color:#dfe1e2;
            color:#333333;
            padding:30px 0;
            border-bottom: 1px solid #d1d1d1;
            cursor: pointer;
        }
        .article-item{
            font-size:16px;
            background-color:#dfe1e2;
            color:#000000;
            padding:14px 0;
            border-bottom: 1px solid #d1d1d1;
            cursor: pointer;
        }
    }
    .article-right{
        flex:6;
        background-color: #FFFFFF;
        padding:40px 60px;
        height: 100%;
        .right-header{
            display: flex;
            .a{
                font-weight: bold;
                color:#000000;
                font-size: 22px;
            }
            .b{
                flex:1;
                border-bottom: 1px solid #f1f1f1;
                text-align: right;
                color: #666666;
                font-size: 14px;
                font-family: Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
        }
        .right-content{
            margin-top: 20px;
            line-height: 32px;
            text-align: left;
        }
        .right-content-list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 20px ;
            padding-bottom: 10px;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
</style>
